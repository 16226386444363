.sidebarArea {
  flex-shrink: 0;
  width: $sidebarWidth;
  background-color: $sidebarColor;
  .sidenav-bg:hover {
    background-color: $secondary;
    border-radius: $border-radius;
    margin:2px 0 2px 0;
  }
  .sidenav-bg {
    font-size:14px;
    font-weight: 700;
    }
    .nav-link{
      color:white;
      font-size: 16px;
    }
    .nav-link:hover{
      color:#DB0006;
    }
    .text-active{
      color:#DB0006 !important;
      background-color: $secondary;
      border-radius: $border-radius;
    }
}

@include media-breakpoint-down(lg) {
  .sidebarArea {
    position: fixed;
    height: 100%;
    overflow: auto;
    z-index: 1;
    margin-left: -$sidebarWidth;
    transition: 0.2s ease-in;
    &.showSidebar {
      margin-left: 0px;
    }
  }
}
