.pageWrapper {
  min-height: 100vh;
}
.contentArea {
  flex-grow: 1;
  background-color: $black;
  
}
.wrapper {
  max-width: 1300px;
  margin: 0 auto;
}
.card {
  box-shadow: $card-box-shadow;
  margin-bottom: 30px;
}

.circle-box {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 100%;
  background-color: black;
  &.lg-box {
    width: 60px;
    height: 60px;
    font-size: 32px;
    line-height: 60px;
  }
}
.button-group .btn {
  margin: 3px;
}

.table>:not(:first-child) {
  border-top: 2px solid;
}
.table-borderless>:not(:first-child) {
  border-top-width: 0;
}
.wallet{
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}
.wallet-body{
  background-color: $gray-200;
   padding: 10px;
   border-radius: 5px;
   margin-bottom: 10px;
 }
.navbar-custom {
  background-color: $dark; 
 // border-radius: 0 0 10px 10px; 
}
.topo{
  background-color: $dark;
  padding: 10px;
  border-radius: 0 0 50px 50px !important;
  margin-bottom: 10px;
  color: white;
}
.text-alpha{
  color:#DB0006 !important;
  border: none;
}
.text-alpha:hover{
  color:#b40107 !important;
  border: none;
}
.button-alpha{
  background-color:#DB0006 !important;
}
.eye-icon{
  font-size: 1.5rem !important; 
  color: white ; 
}
.eye-icon:hover , .eye-icon:active{
  color: #DB0006 !important; 
}
.cards-topo{
  background-color: $dark;
  color: white !important;
}
.cards-topo:hover{
  background-color: #4d4d4d;
}
.text-card-topo{
  font-weight: 600 !important;
}
.input-transparente , .input-transparente select {
  background-color: black !important; /* Fundo transparente */
  color: white !important; /* Texto em cinza */
  border-color: white !important; /* Bordas em cinza para destacar o input */
}

/* CustomTableStyles.css */
.custom-table {
 // background-color: #343a40 !important; /* Fundo preto */
  color: white !important; /* Letra branca */
  border-radius: 20px !important; /* Bordas arredondadas */
  overflow: hidden; /* Garante que o overflow dentro da tabela não ultrapasse o border-radius */
}

.custom-table thead th {
  border-color: white !important; /* Cor da borda dos cabeçalhos */
}

.custom-table tbody td {
  border-color: white !important; /* Cor da borda das células */
}

table{
  border-radius: 20px !important;
  padding: 20px !important;
}
.bold{
  font-weight: 600 !important;
}
.dtopo .card{
  margin-top: -20px;
}
.icon-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}
.dropdown-menu{
  position: absolute !important; 
  inset: 0px auto auto 0px !important; 
  transform: translate3d(0px, 54px, 0px) !important;
}

.floating-button {
  position: fixed; /* Mantém o botão fixo na posição */
  bottom: 20px; /* Distância do fundo da viewport */
  right: 20px; /* Distância do lado direito da viewport */
  padding: 10px 20px; /* Ajuste conforme necessário */
  background-color: #DB0006; /* Cor de fundo do botão */
  color: #ffffff; /* Cor do texto */
  text-align: center;
  border-radius: 5px; /* Arredondamento das bordas */
  text-decoration: none; /* Remove o sublinhado do link */
  /* Adicione qualquer outro estilo que desejar */
}

.logo{
  width: 300px;

}
.area-segura{
  display: none;
}
.notification-dot {
  position: absolute;
  top: 5px;
  right: 12px;
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
}
.copiar{
  margin-left: 10px;
  cursor: pointer;
}

.dropdown-notification {
  max-width: 400px; /* Aumente a largura máxima do menu suspenso */
  min-width: 300px; /* Defina uma largura mínima */
  left: -300px !important; /* Adicione esta linha para anular a posição à direita */
  right: 0; /* Posicione o dropdown à esquerda do botão */
}

.dropdown-user {
  //max-width: 400px; /* Aumente a largura máxima do menu suspenso */
  min-width: 100px; /* Defina uma largura mínima */
  left: -100px !important; /* Adicione esta linha para anular a posição à direita */
  right: 0; /* Posicione o dropdown à esquerda do botão */
}

.dropdown-item-notification {
  white-space: normal; /* Permite a quebra de linha normal */
  word-wrap: break-word; /* Quebra as palavras longas se necessário */
  overflow-wrap: break-word; /* Força a quebra de palavras longas */
  padding-left: 20px; /* Adiciona padding à esquerda */
}

 
@media (max-width: 768px) {

  .dropdown-notification {
    min-width: 300px; /* Defina uma largura mínima */
    left: auto; /* Anule a posição à esquerda */
    right: 0; /* Posicione o dropdown à esquerda do botão */
    transform: translateX(-100%); /* Desloque o dropdown completamente para a esquerda */
  }

  .logo {
    width: 160px !important; 
  }

  .contentArea {
    flex-grow: 1;
    background-image: url('/assets/images/background/328x480.jpg');
    background-size: cover; 
    background-position: center; 
    background-repeat: no-repeat; 
    background-color: $black; 
  }

  .area-segura{
    display: block;
  }

  .qr_code {
    flex-direction: column; /* Organiza os elementos em coluna */
  }
  .copiar{
    margin-top: 5px;
    cursor: pointer;
    
  }
  .dropdown-menu {
    display: block !important; /* Force display: block on mobile */
  }

  .navbar,
  .container-fluid {
    justify-content: normal !important;
  }

  .text-alpha{
    font-size: 12px !important;
  }
  .topo {
    display: flex;
    justify-content: space-between;
    //align-items: center;
    width: 100%;
  }
  
  

  
}


